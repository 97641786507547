import Carousel from './Carousel';
import ContentListLoading from './ContentListLoading';
import { Placeholder } from './styles';
import { WidgetWrapper } from './WidgetManager';

export default function WidgetLoading() {
  return (
    <WidgetWrapper>
      <Carousel m={0} title={<Placeholder width={8} />} arrows={false}>
        <ContentListLoading length={8} />
      </Carousel>
    </WidgetWrapper>
  );
}
