import styled from '@emotion/styled';
import { Flex } from './styles';
import { mainBg } from './styles/Main';

const remSize = .25;

const Bullet = styled.button(({ theme }) => {
  const [_, backgroundColor] = theme.palette.background[mainBg];
  return ({
    width: `${remSize}rem`,
    height: `${remSize}rem`,
    borderRadius: theme.radius.pill,
    padding: 0,
    margin: `${remSize / 2}rem`,
    backgroundColor: theme.rgba(backgroundColor, 0.2),
    cursor: 'pointer',
    '&.active': {
      backgroundColor,
    },
  });
});

interface SliderIndicatorsProps {
  length: number;
  value?: number;
  onChange?: (index: number) => void;
}

export default function SliderIndicators({ length, value, onChange }: SliderIndicatorsProps) {
  return (
    <Flex nowrap p={1}>
      {Array.from({ length }, (_, index) => (
        <Bullet
          key={index}
          className={value === index ? 'active' : ''}
          onClick={() => onChange?.(index)}
        />
      ))}
    </Flex>
  );
}
