import { IconAngleLeft, IconAngleRight } from 'common/icons';
import { IconButton, Flex, Icon } from 'common/styles';
import React, { useState } from 'react';
import { globalPadding } from './Layout/Layout';
import Tooltip from './Tooltip';

export default function SliderNav({ onChange }: SliderNavProps) {
  return (
    <Flex fullWidth px={globalPadding}>
      <NavButton type="prev" onClick={(() => onChange(-1))} />
      <NavButton type="next" onClick={(() => onChange(1))} />
    </Flex>
  );
}

const NavButton = ({ type, onClick: handleClick }: {
  type: 'next' | 'prev';
  onClick: () => void;
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  return <>
    <IconButton p={1} round variant="secondary" onClick={handleClick} mr={2} ref={setReferenceElement}>
      <Icon size="large" cursor="pointer">{type === 'next' ? <IconAngleLeft /> : <IconAngleRight />}</Icon>
    </IconButton>
    <Tooltip anchor={referenceElement} placement="bottom" type={'default'}>
      {type === 'next' ? 'بعدی' : 'قبلی'}
    </Tooltip>
  </>;
};

type SliderNavProps = {
  onChange: (diff: number) => void;
};
