import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { WidgetItemSlider } from './apiService';
import SliderIndicators from './SliderIndicators';
import SliderItem, { itemWrapperOffsetBottom } from './SliderItem';
import SliderNav from './SliderNav';
import { responsiveProps } from './theme';
import Swipe from 'react-easy-swipe';

const delay = 60000;

export default function Slider({ items = [], serverTime }: { items: WidgetItemSlider[], serverTime: string | undefined }) {
  const length = items?.length ?? 0;
  const [active, setActive] = useState(0);
  const [direction, setDirection] = useState<'left' | 'right'>('left');
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (referenceElement) {
      const handleMouseEnter = () => setIsHovering(true);
      const handleMouseLeave = () => setIsHovering(false);

      referenceElement.addEventListener('mouseenter', handleMouseEnter);
      referenceElement.addEventListener('focus', handleMouseEnter);
      referenceElement.addEventListener('mouseleave', handleMouseLeave);
      referenceElement.addEventListener('blur', handleMouseLeave);
      return () => {
        referenceElement.removeEventListener('mouseenter', handleMouseEnter);
        referenceElement.removeEventListener('focus', handleMouseEnter);
        referenceElement.removeEventListener('mouseleave', handleMouseLeave);
        referenceElement.removeEventListener('blur', handleMouseLeave);
      };
    }
  }, [referenceElement]);

  const handleNav = useCallback(
    (diff: number): void => {
      if (length < 1) return;

      setDirection(diff > 0 ? 'left' : 'right');
      setActive((prev) => {
        let newIndex = prev + diff;
        if (newIndex > length - 1) newIndex = 0;
        if (newIndex < 0) newIndex = length - 1;
        return newIndex;
      });
    },
    [length],
  );

  const handleIndicate = (index) => {
    setActive(index);
  };

  useEffect(() => {
    if (length > 1) {
      const id = setTimeout(() => {
        handleNav(+1);
      }, delay);
      return () => {
        clearTimeout(id);
      };
    }
  }, [active, handleNav, length]);

  return (
    <Swipe onSwipeLeft={() => handleNav(-1)} onSwipeRight={() => handleNav(1)} tolerance={50}>
      <Container ref={setReferenceElement}>
        {items.map((item, idx) => (
          <SliderItem key={item.image_id} active={idx === active} direction={direction} serverTime={serverTime} {...item} />
        ))}
        <Navigation length={length} activeIndex={active} onNav={handleNav} showIndicators={isHovering}
          setActiveIndex={(index) => handleIndicate(index)} />
      </Container>
    </Swipe>
  );
}

const Navigation = ({ length, activeIndex, onNav: handleNav, showIndicators, setActiveIndex }: NavigationProps) => {
  if (length < 1) return null;

  return (
    <>
      {showIndicators && <IndicatorsWrapper>
        <SliderIndicators length={length} value={activeIndex} onChange={(index) => setActiveIndex(index)} />
      </IndicatorsWrapper>
      }
      <NavWrapper>
        <SliderNav onChange={handleNav} />
      </NavWrapper>
    </>
  );
};

export const ratio: responsiveProps<number> = {
  xs: 6 / 5,
  sm: 9 / 16,
};

const Container = styled.div(({ theme }) => ({
  position: 'relative',
  zIndex: 0,
  ...theme.mixins.create(ratio, (r) => ({ paddingTop: `${r * 100}%` })),
}));

const NavWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  zIndex: 3,
  width: 'auto',
  left: 0,
  ...theme.mixins.create(itemWrapperOffsetBottom, (bottom, bp) => ({
    display: bp === 'md' ? 'block' : undefined,
    bottom,
  })),
  display: 'none',
}));

const IndicatorsWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  zIndex: 4,
  ...theme.mixins.create(itemWrapperOffsetBottom, (bottom, bp) => ({
    display: bp === 'md' ? 'block' : undefined,
    bottom,
  })),
  display: 'none',
  right: '50%',
  transform: 'translate(50%, 100%)',
  padding: theme.spacing(1),
}));

interface NavigationProps {
  length: number;
  activeIndex: number;
  // onSelect: (idx: number) => void;
  onNav: (diff: number) => void;
  showIndicators: boolean;
  setActiveIndex: (index: number) => void
}
